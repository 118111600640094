.edit-customer-submit {
    display: block !important;
}
.edit-customer-submit button,
.edit-customer-create {
    float: right;
}
.MuiInput-underline:before {
    border-color: #D2D2D2;
}
.MuiInput-underline:after {
    border-bottom-width: 1px !important;
}
.edit-customer-qr svg,
.edit-customer-qr canvas {
    height: auto !important;
    max-width: 100%;
}
.rt-td .MuiCheckbox-root {
    cursor: default;
}

.rt-td .MuiCheckbox-root:hover {
    background-color: transparent !important;
}
.MuiInputBase-input.Mui-disabled{
    color: rgba(0, 0, 0, 0.87) !important;
}
.rdt input:disabled {
    color: rgba(0, 0, 0, 0.87) !important;
}
.btn.danger{
    color: white;
}
.centerButton {
    display: flex !important;
    margin: auto !important;
    margin-bottom: 20px !important;
}
.nationality-autocomplete {
    padding: 0 15px;
}
.nationality-input {
    margin-top: 8px !important;
}
.NewEmployeeTypeModal .-pageJump {
	max-width: 65px;
}
.content-button-add {
    padding: 0 5px !important;
}
.content-button-add button {
    margin-top: 0;
}
.no-padding-bottom{
    padding-bottom: 0 !important;
} 
@media (min-width: 600px) {
    .edit-customer-form .MuiGrid-grid-sm-7,
    .MuiDialogContent-root .MuiGrid-item:not(.check-item) .MuiGrid-grid-sm-7 {
        max-width: 100%;
        flex-basis: 100%;
    }
}
.infoIcon {
    opacity: .6;
}

.fees-dialog h2> span strong {
    font-size: 1.1rem;
}