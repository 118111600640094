.employees {
    display: flex;
    justify-content: space-between;
}
.employees-content {
    width: calc(100% - 260px);
}
.employees-filters {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.employees-filters-row {
    width: 100%;
}
.employees-dropdown {
    width: 20%;
}
.employees-content-mini {
    width: calc(100% - 80px)
}
.employees-content-header,
.employees-content-header-mini {
    min-height: 70px;
}
.employees-content-header header {
    width: calc(100% - 260px);
}
.employees-content-header-mini header {
    width: calc(100% - 80px)
}
.filtrable-table .ReactTable .rt-thead.-header {
    z-index: 3;
}
.employees-content-body {
    max-height: 540px;
    overflow: hidden;
}

.employees-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
}
.employees-modules {
    width: 50%;
    padding: 0 1rem;
}
.employees-label.MuiFormLabel-root {
    padding: 0 1rem 0 0;
}
.employees-input {
    display: flex;
    align-items: baseline;
    justify-content: space-evenly;
    height: 70px;
}
.employees-content-body div {
    width: 100%;
}
.MuiFormControl-root.employees-dropdown {
    min-width: 150px;
    max-width: 150px;
}
.employeess-submit {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.employees-modules-button {
    float: right;
}
.employees-input-number {
    max-width: 50px;
}
.employees-dialog {
    width: 60%;
    margin: auto;
}
.employees-dialog .employees-input {
    justify-content: space-between;
}
.employees-actions {
    display: flex;
    justify-content: center;
}
.employees-actions button {
    padding: 5px;
    width: auto;
    height: auto;
    min-width: auto;
    font-size: 12px;
}
.employees-button {
    float: right;
}
.employees-button-icon.MuiSvgIcon-root {
    margin: auto;
}
.employees-filters-row {
    width: 100%;
}
.employees-table .ReactTable .column-expandable,
.employees-table .ReactTable .rt-thead .rt-resizable-header:last-child,
.employees-table .ReactTable .rt-thead .rt-th:last-child  {
    width: 40px !important;
    margin: 0;
}

@media screen and (max-width: 976px) {
    .employees-content {
        width: 100%;
    }
    .employees-content-header header{
        width: 100%;
    }
    .employees-content-body {
        flex-direction: column;
    }
    .employees-modules, .employees-form {
        width: 100%;
    }
}

.vehicle-title{
    width: 100%;
}