.select-input .MuiInputLabel-formControl {
    opacity: 1;
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.42857;
}

.select-input .MuiInputLabel-formControl.invalid {
    color: #EA5757 !important;
}

.select-input .MuiFormControl-fullWidth {
    width: 100%;
    margin: 0 0 17px 0;
    position: relative;
    vertical-align: unset;
}

.MuiFormHelperText-root.helper-error {
    color: #EA5757;
}

/*  custom combobox */
.MuiAutocomplete-endAdornment .MuiButtonBase-root .MuiSvgIcon-fontSizeSmall {
    width: 18px;
    height: 18px;
}
.MuiAutocomplete-root .MuiInput-underline:before {
    border-color: #D2D2D2!important;
    border-width: 1px !important;
}
.MuiAutocomplete-root > .MuiTextField-root > .MuiInput-underline:after,
.MuiAutocomplete-root > .MuiTextField-root > .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid #5DC4B9 !important;
}
