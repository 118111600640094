.day-picker {
    display: flex;
    align-items: center;
}
button.day-check {
    background: rgba(183, 189, 197, .5) !important;
    border-radius: 100%;
    color: #999999 !important;
    cursor: pointer;
    font-size: 10px;
    font-weight: 500;
    height: 24px;
    margin: .2rem;
    padding: 0;
    width: 24px;
}
button.day-check.checked {
    background: #3049C9 !important;
    color: #fff !important;
}
.picker-label {
    font-size: 1em;
}
