.full-loading-animation .heart {
    animation-name: heartbeat;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

@keyframes heartbeat {
    0%   { transform: scale(1);    }
    30%  { transform: scale(1);    }
    40%  { transform: scale(1.12); }
    50%  { transform: scale(1);    }
    60%  { transform: scale(1);    }
    70%  { transform: scale(1.08); }
    80%  { transform: scale(1);    }
    100% { transform: scale(1);    }
}
