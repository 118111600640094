.new-sypplycatalog-label.MuiFormLabel-root {
    padding: 0 1rem 0 0;
}
.new-sypplycatalog-row {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}
.new-sypplycatalog-row .MuiFormControl-root {
    padding-right: 1rem;
}

@media (min-width: 600px) {
    .new-sypplycatalog-form .MuiGrid-grid-sm-7 {
        max-width: 100%;
        flex-basis: 100%;
    }
}