.edit-practice-form div.label-checkbox {
    padding-top: 16px !important;
}
.edit-practice-form div.label-checkbox > label > span.MuiFormControlLabel-label {
    font-size: 1em;
}
@media (min-width: 600px) {
    .edit-practices-form .MuiGrid-grid-sm-7,
    .MuiGrid-container .MuiGrid-grid-sm-7 {
        max-width: 100%;
        flex-basis: 100%;
    }
}
.input-form-text-field {
    padding: 0 15px !important;
    margin: 10px 0 17px !important;
}
