.new-user-form .radio-input .MuiFormControlLabel-label {
    align-items: center;
    font-size: 0.8rem;
}
.new-user-form form {
    margin-bottom: 15px;
}
.new-user-form form .radio-input-group {
    width: 33%;
}
.new-user-form form .infoIcon {
    font-size: 1rem;
    width: auto !important;
    position: relative;
    top: -5px;
    padding-left: 5px;
}
.new-user-form .required-text {
    font-size: 0.875rem;
    padding-left: 30px;
}
.MuiIconButton-label .checked-icon {
    width: 20px;
    border: 1px solid rgba(0, 0, 0, .54);
    height: 20px;
    border-radius: 3px;
}
.MuiIconButton-label .unchecked-icon {
    color: transparent;
    width: 20px;
    border: 1px solid rgba(0, 0, 0, .54);
    height: 20px;
    border-radius: 3px;
}

@media screen and (max-width: 768px) {
    .new-user-form form .radio-input-group {
        width: auto;
    }
  }