.ReactTable .rt-td {
  align-self: center;
  margin: 10px 0;
}
.filters {
  align-items: center;
  clear: both;
  display: flex;
  justify-content: flex-start;
}
.filters .MuiSelect-select {
  white-space: normal;
}
.filters .MuiSelect-select div .MuiChip-root {
  margin: 2px;
}
.filters .MuiSelect-select div .MuiChip-root .MuiChip-label {
  font-size: 0.7rem;
}
.filters .MuiGrid-root .MuiGrid-grid-sm-12 {
  padding: 0 !important;
}
.MuiButton-root.RegularButton-block-290 + .MuiButton-root.RegularButton-block-290 {
  margin-left: 0;
}
.dashboard-filters .rdt {
  padding: 0 1rem 0 0;
}
.dashboard-filters-dropdown {
  width: 100%;
}
.dashboard-content {
  width: calc(100% - 260px);
}
.dashboard-content-mini {
  width: calc(100% - 80px);
}
.dashboard-content-header,
.dashboard-content-header-mini {
  min-height: 70px;
}
.dashboard-content-header header {
  width: calc(100% - 260px);
}
.dashboard-content-header-mini header {
  width: calc(100% - 80px);
}
.dashboard-content-body {
  max-height: 594px;
  overflow: auto;
}
.dashboard-charts {
  display: flex;
}
.dashboard-link {
  cursor: pointer;
  color: #00bcd4;
}
.dashboard .chips {
  display: flex;
  flex-wrap: wrap;
}
.ct-label.ct-label.ct-horizontal.ct-end {
  transform: translateX(-40%) translateY(30%) rotate(-45deg) !important;
  font-size: 12px !important;
}
.dashboard-actions {
  display: flex;
  justify-content: center;
}
.dashboard-actions button {
  padding: 5px;
  width: auto;
  height: auto;
  min-width: auto;
  font-size: 12px;
}

@media screen and (max-width: 976px) {
  .dashboard-content {
    width: 100%;
  }
  .dashboard-content-header header {
    width: 100%;
  }
}

.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.02);
}
.rt-tbody {
  font-size: 14px;
}
.chat-style {
  font-size: 30px;
}
.pie_color1 {
  background: #d81b60;
  fill: #d81b60;
}
.pie_color2 {
  background: #00b359; 
  fill: #00b359;
}
.pie_color3 {
  background: #bf00b0;
  fill: #bf00b0;
}
.pie_color4 {
  background: #f44224;
  fill: #f44224;
}
.pie_color5 {
  background: #dec02b;
  fill: #dec02b;
}
.pie_color6 {
  background: #4fc710;
  fill: #4fc710;
}
.pie_color7 {
  background:#f06292;
  fill: #f06292;
}
.pie_color8 {
  background:#1bd893;
  fill: #1bd893;
}
.pie_color9 {
  background:#e157ca;
  fill:#e157ca;
}
.pie_color10 {
  background: #ff8367;
  fill: #ff8367;
}
.pie_color12 {
  background: #e8dd6c;
  fill: #e8dd6c;
}
.pie_color13 {
  background: #98e570;
  fill: #98e570;
}
.ct-series-a .ct-bar,
.ct-series-a  {
  background:#00b359;
  stroke:#00b359;
}
.ct-series-b .ct-bar,
.ct-series-b  {
  background: #d81b60;
  stroke: #d81b60;
}
.ct-series-c .ct-bar,
.ct-series-c  {
  background: #bf00b0;
  stroke: #bf00b0;
}
.ct-series-d .ct-bar,
.ct-series-d  {
  stroke: #f44224;
  background: #f44224;
}
.ct-series-e .ct-bar,
.ct-series-e  {
  stroke:#dec02b;
  background:#dec02b;
}
.dot {
  border-radius: 50%;
  display: inline-block;
  height: 25px;
  width: 25px;
}
.labelLeyend {
  color: rgb(82, 80, 80);
}
.ct-label {
  font-size: 10px;
}
.date-no-padding .date-input > .MuiGrid-item {
  padding: 0 !important;
}
