* {
  font-family: 'Roboto', sans-serif;
}
.radio-input-group {
  display: flex;
  flex-direction: row;
}
.radio-input{
  display: flex;
}
.MuiFormGroup-root {
  flex-direction: row !important;
}

.ct-label.ct-label.ct-horizontal.ct-end {
  position: relative;
  justify-content: flex-start;
  text-align: center;
  white-space:nowrap;
}

.ct-chart-bar > .ct-labels > foreignObject >.ct-label.ct-horizontal.ct-end {
  left: calc(100% / 2);
}
.radio-others-container {
  align-items: flex-end;
  display: flex;
}

.table-simple-left > .rt-table > .rt-tbody {
  text-align: left;
}
.headerVitalSigns{
  text-transform: unset;
}
.vitalSignsView .ct-horizontal{
  top: -12px
}
.Mui-selected .HearthPulseIcon {
  filter: invert(0.23) sepia(1) saturate(10) hue-rotate(211deg)
}
.HearthPulseIcon {
  filter: invert(0.45) sepia(0);
}
.TabsTecnicalFile .MuiTabs-indicator {
  width: 145px !important;
}
.MuiTab-root {
  min-width: 148px;
}
.TecnicalFileTab svg{
  padding-bottom: 5px;
  padding-top: 4px;
}
.aditional-information .item-radio {
  margin-bottom: 15px;
}

.appointment-info .MuiGrid-item:not(.MuiGrid-container) {
  padding: 0 15px;
}
