.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
    display: block;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.ct-label {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1;
}


.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
    stroke: #00bcd4;
}

.ct-series-a .ct-slice-pie,
.ct-series-a .ct-area {
    fill: #00bcd4;
}

.ct-series-b .ct-point,
.ct-series-b .ct-line,
.ct-series-b .ct-bar,
.ct-series-b .ct-slice-donut {
    stroke: #EA5757;
}

.ct-series-b .ct-slice-pie,
.ct-series-b .ct-area {
    fill: #EA5757;
}

.ct-series-c .ct-point,
.ct-series-c .ct-line,
.ct-series-c .ct-bar,
.ct-series-c .ct-slice-donut {
    stroke: #ff9800;
}

.ct-series-c .ct-slice-pie,
.ct-series-c .ct-area {
    fill: #ff9800;
}

.ct-series-d .ct-point,
.ct-series-d .ct-line,
.ct-series-d .ct-bar,
.ct-series-d .ct-slice-donut {
    stroke: #854FCB;
}

.ct-series-d .ct-slice-pie,
.ct-series-d .ct-area {
    fill: #854FCB;
}

.ct-series-e .ct-point,
.ct-series-e .ct-line,
.ct-series-e .ct-bar,
.ct-series-e .ct-slice-donut {
    stroke: #5DC4B9;
}

.ct-series-e .ct-slice-pie,
.ct-series-e .ct-area {
    fill: #5DC4B9;
}

.ct-series-f .ct-point,
.ct-series-f .ct-line,
.ct-series-f .ct-bar,
.ct-series-f .ct-slice-donut {
    stroke: #9C9B99;
}

.ct-series-f .ct-slice-pie,
.ct-series-f .ct-area {
    fill: #9C9B99;
}

.ct-series-g .ct-point,
.ct-series-g .ct-line,
.ct-series-g .ct-bar,
.ct-series-g .ct-slice-donut {
    stroke: #999999;
}

.ct-series-g .ct-slice-pie,
.ct-series-g .ct-area {
    fill: #999999;
}

.ct-series-h .ct-point,
.ct-series-h .ct-line,
.ct-series-h .ct-bar,
.ct-series-h .ct-slice-donut {
    stroke: #dd4b39;
}

.ct-series-h .ct-slice-pie,
.ct-series-h .ct-area {
    fill: #dd4b39;
}

.ct-series-i .ct-point,
.ct-series-i .ct-line,
.ct-series-i .ct-bar,
.ct-series-i .ct-slice-donut {
    stroke: #35465c;
}

.ct-series-i .ct-slice-pie,
.ct-series-i .ct-area {
    fill: #35465c;
}

.ct-series-j .ct-point,
.ct-series-j .ct-line,
.ct-series-j .ct-bar,
.ct-series-j .ct-slice-donut {
    stroke: #e52d27;
}

.ct-series-j .ct-slice-pie,
.ct-series-j .ct-area {
    fill: #e52d27;
}

.ct-series-k .ct-point,
.ct-series-k .ct-line,
.ct-series-k .ct-bar,
.ct-series-k .ct-slice-donut {
    stroke: #55acee;
}

.ct-series-k .ct-slice-pie,
.ct-series-k .ct-area {
    fill: #55acee;
}

.ct-series-l .ct-point,
.ct-series-l .ct-line,
.ct-series-l .ct-bar,
.ct-series-l .ct-slice-donut {
    stroke: #cc2127;
}

.ct-series-l .ct-slice-pie,
.ct-series-l .ct-area {
    fill: #cc2127;
}

.ct-series-m .ct-point,
.ct-series-m .ct-line,
.ct-series-m .ct-bar,
.ct-series-m .ct-slice-donut {
    stroke: #1769ff;
}

.ct-series-m .ct-slice-pie,
.ct-series-m .ct-area {
    fill: #1769ff;
}

.ct-series-n .ct-point,
.ct-series-n .ct-line,
.ct-series-n .ct-bar,
.ct-series-n .ct-slice-donut {
    stroke: #6188e2;
}

.ct-series-n .ct-slice-pie,
.ct-series-n .ct-area {
    fill: #6188e2;
}

.ct-series-o .ct-point,
.ct-series-o .ct-line,
.ct-series-o .ct-bar,
.ct-series-o .ct-slice-donut {
    stroke: #a748ca;
}

.ct-series-o .ct-slice-pie,
.ct-series-o .ct-area {
    fill: #a748ca;
}

.ct-label.ct-horizontal {
    font-size: 10px !important;
    transform: translateX(-25%) translateY(10%) rotate(-45deg);
    width: 40px !important;
}

.ct-chart-bar .ct-label.ct-horizontal {
    margin: auto;
}