.flex-end {
    justify-content: flex-end !important;
}
.MuiInput-underline:before {
    border-color: #D2D2D2;
}
.MuiInput-underline:after {
    border-bottom-width: 1px !important;
}
.edit-customer-qr svg,
.edit-customer-qr canvas {
    height: auto !important;
    max-width: 100%;
}

.rt-td .MuiCheckbox-root {
    cursor: default;
}

.rt-td .MuiCheckbox-root:hover {
    background-color: transparent !important;
}
.nationality-autocomplete {
    padding: 0 15px;
}
.nationality-input {
    margin-top: 8px !important;
}
.actions-buttons {
    width: 100%;
}
.calendarPicker {
	z-index: 5;
}

.plan-item {
    color: #495057 !important;
    margin-bottom: 0 !important;
    padding-top: 8px !important;
}
.plan-item .MuiSvgIcon-root {
    vertical-align: middle;
}
.plan-item button {
    display: inline-block;
    vertical-align: middle;
    padding: 0;
}
.plan-item button svg {
    padding-left: 4px;
}

@media (min-width: 600px) {
    .edit-customer-form .MuiGrid-grid-sm-7,
    .MuiDialogContent-root .MuiGrid-item:not(.check-item) .MuiGrid-grid-sm-7 {
        max-width: 100%;
        flex-basis: 100%;
    }
}


.custom-checkbox.MuiCheckbox-root.Mui-checked {
    color: #64c4b8; 
}

.custom-checkbox.MuiCheckbox-root.Mui-checked .MuiIconButton-label {
    position: relative;
}

.custom-checkbox.MuiCheckbox-root.Mui-checked .MuiIconButton-label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: currentColor;
    opacity: 0.3;
    border-radius: 50%;
}