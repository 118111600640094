/* Custom Styles */
.logo-sidebar {
    clear: both;
    overflow: hidden;
}
.logo-sidebar img {
    max-width: 150px;
    width: auto;
}
.Sidebar-logoNormal-11 {
    text-decoration: none;
}