.schedule-detail-form {
  display: flex;
  flex-direction: column;
}
.card-title.card-int {
  padding: 15px;
}
.schedule-detail-label.MuiFormLabel-root {
  padding: 0 1rem 0 0;
}
.schedule-detail-row {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.schedule-detail-row .MuiFormControl-root {
  padding-right: 1rem;
}
.schedule-detail .schedule-detail-submit {
  justify-content: flex-end;
}
.iconWhiteCircle{
  background: #fff !important;
  color: #d81b60;
  border-radius: 50% !important;
}
.roseBackground{
  background: #d81b60 ;
  padding: 10px 0;
  color: #fff !important;
  border-radius: 3px;
}
.vital-sign-various-head {
  background-color: #eee !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.vital-sign-various-head h4 {
  padding: 10px 15px;
  margin: 0;
}

@media (min-width: 600px) {
  .schedule-detail-form .MuiGrid-grid-sm-7 {
    max-width: 100%;
    flex-basis: 100%;
  }
}