.tooltypes {
    display: flex;
    justify-content: space-between;
}
.tooltypes-content {
    width: calc(100% - 260px);
}
.filtrable-table .ReactTable .rt-thead.-header {
    z-index: 3;
}
.tooltypes-filters {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.tooltypes-filters-row {
    width: 100%;
}
.tooltypes-dropdown {
    width: 20%;
}
.tooltypes-content-mini {
    width: calc(100% - 80px)
}
.tooltypes-content-header, .tooltypes-content-header-mini {
    min-height: 70px;
}
.tooltypes-content-header header{
    width: calc(100% - 260px);
}
.tooltypes-content-header-mini header {
    width: calc(100% - 80px)
}
.tooltype-content-body {
    max-height: 500px;
    overflow: auto;
    display: flex;
    justify-content: space-evenly;
}
.tooltypes-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
}
.tooltypes-modules {
    width: 50%;
    padding: 0 1rem;
}
.tooltypes-label.MuiFormLabel-root {
    padding: 0 1rem 0 0;
}
.tooltypes-input {
    display: flex;
    align-items: baseline;
    justify-content: space-evenly;
    height: 70px;
}
.tooltypes-content-body div {
    width: 100%;
}
.MuiFormControl-root.tooltypes-dropdown {
    min-width: 150px;
    max-width: 150px;
}
.tooltypes-submit {
    justify-content: flex-end !important;
}
.tooltypes-modules-button {
    float: right;
}
.tooltypes-input-number {
    max-width: 50px;
}
.tooltypes-dialog {
    width: 60%;
    margin: auto;
}
.tooltypes-dialog .tooltypes-input {
    justify-content: space-between;
}
.tooltypes-actions {
    display: flex;
    justify-content: center;
}
.tooltypes-actions button {
    padding: 5px;
    width: auto;
    height: auto;
    min-width: auto;
    font-size: 12px;
}
.tooltypes-button {
    float: right;
}
.tooltypes-button-icon.MuiSvgIcon-root {
    margin: auto;
}

@media screen and (max-width: 976px) {
    .tooltypes-content {
        width: 100%;
    }
    .tooltypes-content-header header{
        width: 100%;
    }
    .tooltypes-content-body {
        flex-direction: column;
    }
    .tooltypes-modules, .tooltypes-form {
        width: 100%;
    }
}