.MuiInput-underline:before {
    border-color: #D2D2D2;
}
.MuiInput-underline:after {
    border-bottom-width: 1px !important;
}
.rt-td .MuiCheckbox-root {
    cursor: default;
}

.rt-td .MuiCheckbox-root:hover {
    background-color: transparent !important;
}

@media (min-width: 600px) {
    .edit-tooltype-form .MuiGrid-grid-sm-7,
    .MuiDialogContent-root .MuiGrid-item:not(.check-item) .MuiGrid-grid-sm-7 {
        max-width: 100%;
        flex-basis: 100%;
    }
}
