.schedule-form {
    z-index: 1;
}
.fullWidth-input .select-input-container,
.fullWidth-input .MuiGrid-root .MuiGrid-root {
    width: 100% !important;
    padding: 0 !important;
    max-width: 100%;
    flex-basis: 100%;
}
.card-customer-container .fullWidth-input {
    margin-bottom: 7.5px;
}
.fullWidth-input:not(.validation-input) .MuiFormControl-fullWidth {
    margin: 9px 0 17px;
}
.fullWidth-input:not(.validation-input) .date-input .MuiFormControl-fullWidth {
    margin-top: 0;
}
.fullWidth-input .MuiFormControl-fullWidth .MuiFormLabel-root {
    font-size: 1em;
}
.schedule-detail .ReactTable .rt-td {
    display: flex;
    justify-content: center;
    align-items: center;
}
.schedule-form-submit {
    padding: 0 5px !important;
}
.schedule-form-submit:not(.invalid-text) {
    justify-content: flex-end !important;
}
.schedule-action-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}
.full-width {
    width: 100% !important;
} 
.schedule-grid-submit {
    display: flex;
}
.date-input-grid {
    z-index: 2 !important;
}
.date-filter {
    z-index: 2;
}
    
.MuiGrid-item.availability-label {
    margin-top: 9px;
}

.availability-label label{
    padding: 0 15px;
}
.MuiGrid-container.schedule-plan {
    padding: 0 15px;
}
.availability-label label.label-text,
label.schedule-plan-title,
h3.schedule-plan-title {
    color: #002859;
    font-size: 1rem;
    font-weight: 500;
    padding: 15px 15px 0;
}

.schedule-errors {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
}

.schedule-errors-item .MuiIcon-colorDisabled {
    color: orange;
}
.schedule-errors-item .material-icons {
    margin-right: 8px;
}
.schedule-errors-actions {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
}

.componentType {
    width: 100%;
}

.schedule-errors-actions button + button {
    margin-left: 15px;
}

.disabled-button {
    background-color: #939393 !important;
}

.MuiGrid-item.schedule-form-submit {
    padding-top: 35px !important;
}

.form-module-container .MuiGrid-item.schedule-form-submit button {
    max-width: calc(50% - 7.5px);
    padding: 12px 20px;
}

.form-module-container .MuiGrid-item.schedule-form-submit button span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.diagnostic-form-autocomplete {
    font-size: 14px;
    margin-bottom: 2vh;
    margin-top: 2vh;
}

.diagnostic-form-text-field {
    margin-top: 2vh !important;
    margin-bottom: 2vh !important;
}

.diagnostic-form-textarea {
    padding: 0px;
}

.MuiInputBase-input.Mui-disabled {
    color: #495057;
}

.cancelation-label { 
    margin-bottom: 0px;
}

.rdt.disabled input {
    color: #495057 !important;
}

.customer-form-container, .responsable-form-container {
    height: 130px;
}
.MuiDialog-paperScrollPaper {
    max-height:  calc(100% - 6px) !important;
}

.form-module-container {
    z-index: 0;
}
.form-practice-container .date-input-grid .date-input .rdt input.form-control {
    font-size: 1em;
}
.diagnostic-form .diagnostic-form-autocomplete .MuiInputLabel-root,
.diagnostic-form .diagnostic-form-text-field .MuiInputLabel-root {
    font-size: 1em;
}
.schedule-plan-group .day-picker {
    font-size: 14px;
}
.schedule-plan-group.days-selector {
    margin: 8px 0 21px;
}
.no-padding {
    padding: 0 !important;
}
.padding {
    padding-left: 10px !important;
}

.plan-content .schedule-plan {
    margin-top: 15px;
}
