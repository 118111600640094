.container.multi-record {
    margin-top: 15px;
}

.record-header {
    background-color: hsl(35, 58%, 94%);
    color: rgba(0, 0, 0, 0.54) !important;
    font-size: 1.1em;
    margin-left: -10px;
    padding: 10px;
    width: calc(100% - 10px);
}

button.link {
    color: #3049C9 !important;
    font-size: .875rem;
    padding: 0;
    text-transform: none;
}

.file {
    font-size: 1.1em;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54) !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.container {
    padding-left: 20px;
}

.no-padding {
    padding-left: -20px !important;
}

.no-data {
    color: rgba(0, 0, 0, 0.54) !important;
    padding: 36px 0;
    text-align: center;
}

.record-text {
    color: #495057;
    padding-top: 10px;
}
.resource-img {
    border-radius: 4px;
}

.signature-item {
    color: #495057 !important;
    margin-bottom: 0;
    padding-top: 15px;
}
.signature-item .MuiSvgIcon-root {
    vertical-align: middle;
}
.signature-item > button {
    display: inline-block;
    vertical-align: middle;
}
.signature-item > button > span {
    top: 2px;
    position: relative;
}
.signature-item > button svg {
    padding-left: 4px;
    padding-right: 4px;
}
.signature-item button.link:hover span span {
    text-decoration: underline;

}
.signature-item span small {
    font-weight: 300;
}
