* {
    font-family: "Roboto", sans-serif;
  }
  .appointment-detail .appointment-detail-schedule {
    justify-content: space-between;
  }
  
  .error {
    color: #d9534f;
  }
  .warning {
    color: #f0ad4e;
  }
  .booking-detail-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px !important;
  }
  
  .booking-errors {
    display: flex;
    flex-direction: column;
    font-family: "Roboto", sans-serif;
  }
  
  .booking-errors-list {
    padding: 0 4rem 1rem 4rem;
  }
  
  .booking-errors-item {
    display: flex;
    justify-content: space-evenly;
  }
  
  .booking-errors-actions {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
  }
  /* calendar */
  .rbc-btn {
    color: inherit;
    font: inherit;
    margin: 0;
  }
  button.rbc-btn {
    background-color: #9c27b0;
    color: #fff;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer;
  }
  button[disabled].rbc-btn {
    cursor: not-allowed;
  }
  button.rbc-input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  .rbc-calendar {
    font-family: "Roboto", sans-serif;
    box-sizing: border-box;
    height: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .rbc-calendar *,
  .rbc-calendar *:before,
  .rbc-calendar *:after {
    box-sizing: inherit;
  }
  .rbc-abs-full,
  .rbc-row-bg {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .rbc-ellipsis,
  .rbc-event-label,
  .rbc-row-segment .rbc-event-content,
  .rbc-show-more {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .rbc-rtl {
    direction: rtl;
  }
  .rbc-off-range {
    color: #333333;
    opacity: 0.3;
  }
  .rbc-header {
    overflow: hidden;
    -webkit-flex: 1 0 0%;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 3px;
    text-align: right;
    vertical-align: middle;
    font-weight: bold;
    font-size: 1em;
    min-height: 0;
    border-bottom: 1px solid #ddd;
    color: #999999;
  }
  .rbc-header:last-child span {
    padding-right: 15px;
  }
  .rbc-header > a,
  .rbc-header > a:active,
  .rbc-header > a:visited {
    color: inherit;
    text-decoration: none;
  }
  .rbc-row-content {
    position: relative;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    z-index: 4;
    min-height: 90px;
  }
  .rbc-toolbar {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .rbc-toolbar .rbc-toolbar-label {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 0 10px;
    text-align: center;
    font-family: inherit;
    line-height: 1.5em;
    color: inherit;
    font-weight: 300;
    margin: 0;
    font-size: 1.6em;
    text-transform: capitalize;
  }
  .rbc-toolbar button {
    border-width: 2px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border: none;
    margin: 10px 1px;
    border-radius: 30px;
    padding: 6px 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    outline: none;
  }
  .rbc-toolbar button:focus {
    outline: none;
  }
  .rbc-btn-group {
    display: inline-block;
  }
  
  .rbc-btn-group button {
    background-color: #9c27b0;
    color: #fff;
    box-shadow: 0 2px 2px 0 rgba(156, 39, 176, 0.14),
      0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border: none;
    margin: 10px 1px;
    border-radius: 30px;
    padding: 6px 12px;
    cursor: pointer;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    outline: none;
  }
  .rbc-btn-group button:hover {
    background-color: #8c279e;
    box-shadow: 0 14px 16px -12px rgba(153, 153, 153, 0.42),
      0 4px 4px 0px rgba(0, 0, 0, 0.12), 0 8px 4px -5px rgba(153, 153, 153, 0.2);
  }
  .rbc-calendar button.rbc-active {
    background-color: #8c279e;
  }
  .alert-rejected-card {
    background-color: #f44236;
    border-radius: 5px;
    color: #fff;
    margin: 20px 0 0;
    padding: 5px;
    text-align: center;
  }
  .alert-rejected-card .alertRejectedText {
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
  }
  .alert-rejected-card .MuiSvgIcon-root {
    margin-bottom: 5px;
    vertical-align: middle;
  }
  
  .event-rejected {
    position: absolute;
  }
  
  @media screen and (max-width: 526px) {
    .rbc-btn-group button {
      margin: 2px !important;
    }
  }
  
  .rbc-btn-group button + button {
    margin: 0 0 0 2px;
    border-radius: 30px;
  }
  .rbc-btn-group + .rbc-btn-group,
  .rbc-btn-group + button {
    margin-left: 10px;
  }
  
  .rbc-slot-selecting .rbc-event {
    cursor: inherit;
    pointer-events: none;
  }
  .rbc-event.rbc-selected {
    background-color: #265985;
  }
  .rbc-event-label {
    font-size: 80%;
  }
  .rbc-event-overlaps {
    box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
  }
  .rbc-event-continues-prior {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .rbc-event-continues-after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .rbc-event-continues-earlier {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .rbc-event-continues-later {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .rbc-event-continues-day-after {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .rbc-event-continues-day-prior {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .rbc-row {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .rbc-row-segment {
    padding: 2px 1px 1px;
  }
  .rbc-selected-cell {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .rbc-show-more {
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 4;
    font-weight: bold;
    font-size: 85%;
    height: auto;
    line-height: normal;
    white-space: nowrap;
  }
  .rbc-month-view {
    position: relative;
    border: 1px solid #ddd;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    width: 100%;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    height: 100%;
    border-left: none;
    border-right: none;
  }
  .rbc-month-view {
    border-top: none;
  }
  .rbc-month-header {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  
  .rbc-month-row {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    -ms-flex-preferred-size: 0px;
    overflow: hidden;
    height: 100%;
  }
  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid #ddd;
  }
  .rbc-date-cell {
    -webkit-flex: 1 1 0;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
    min-width: 0;
    padding-right: 5px;
    text-align: right;
  }
  .rbc-date-cell.rbc-now {
    font-weight: bold;
  }
  .rbc-date-cell > a,
  .rbc-date-cell > a:active,
  .rbc-date-cell > a:visited {
    color: inherit;
    text-decoration: none;
  }
  .rbc-row-bg {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    overflow: hidden;
  }
  .rbc-day-bg {
    -webkit-flex: 1 0 0%;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #ddd;
  }
  .rbc-rtl .rbc-day-bg + .rbc-day-bg {
    border-left-width: 0;
    border-right: 1px solid #ddd;
  }
  .rbc-overlay {
    position: absolute;
    z-index: 5;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    padding: 10px;
  }
  .rbc-overlay > * + * {
    margin-top: 1px;
  }
  .rbc-overlay-header {
    border-bottom: 1px solid #e5e5e5;
    margin: -10px -10px 5px -10px;
    padding: 2px 10px;
  }
  .rbc-agenda-view {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    overflow: auto;
  }
  .rbc-agenda-view table.rbc-agenda-table {
    width: 100%;
    border: 1px solid #ddd;
    border-spacing: 0;
    border-collapse: collapse;
  }
  .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
    padding: 5px 10px;
    vertical-align: top;
  }
  .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
    padding-left: 15px;
    padding-right: 15px;
    text-transform: lowercase;
  }
  .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-left: 1px solid #ddd;
  }
  .rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-left-width: 0;
    border-right: 1px solid #ddd;
  }
  .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
    border-top: 1px solid #ddd;
  }
  .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    padding: 3px 5px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  .rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    text-align: right;
  }
  .rbc-agenda-time-cell {
    text-transform: lowercase;
  }
  .rbc-agenda-time-cell .rbc-continues-after:after {
    content: " »";
  }
  .rbc-agenda-time-cell .rbc-continues-prior:before {
    content: "« ";
  }
  .rbc-agenda-date-cell,
  .rbc-agenda-time-cell {
    white-space: nowrap;
  }
  .rbc-agenda-event-cell {
    width: 100%;
  }
  .rbc-time-column {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100%;
  }
  .rbc-time-column .rbc-timeslot-group {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .rbc-timeslot-group {
    border-bottom: 1px solid #ddd;
    min-height: 40px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
  }
  .rbc-time-gutter,
  .rbc-header-gutter {
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
  }
  .rbc-label {
    padding: 0 5px;
  }
  .rbc-day-slot {
    position: relative;
  }
  .rbc-day-slot .rbc-event {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-height: 100%;
    min-height: 20px;
    -webkit-flex-flow: column wrap;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    overflow: hidden;
    position: absolute;
    z-index: 1;
  }
  .rbc-day-slot .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 10px;
    top: 0;
  }
  .rbc-day-slot .rbc-events-container.rbc-is-rtl {
    left: 10px;
    right: 0;
  }
  .rbc-day-slot .rbc-event-label {
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    width: auto;
    font-weight: bold;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    color: #fff;
    white-space: nowrap;
  }
  .rbc-day-slot .rbc-event-content {
    width: 100%;
    -webkit-flex: 1 1 0;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
    word-wrap: break-word;
    line-height: 1;
    height: 100%;
    min-height: 1em;
  }
  .rbc-day-slot .rbc-time-slot + .rbc-time-slot {
    border-top: 1px dotted #ddd;
  }
  .rbc-time-slot {
    -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
  }
  .rbc-time-slot.rbc-now {
    font-weight: bold;
  }
  .rbc-day-header {
    text-align: center;
  }
  .rbc-slot-selection {
    z-index: 10;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 75%;
    width: 100%;
    padding: 3px;
  }
  .rbc-slot-selecting {
    cursor: move;
  }
  .rbc-time-view {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
    border: 1px solid #ddd;
    min-height: 0;
    border-left: none;
    border-right: none;
    border-top: none;
  }
  .rbc-time-view .rbc-time-gutter {
    white-space: nowrap;
  }
  .rbc-time-view .rbc-allday-cell {
    box-sizing: content-box;
    width: 100%;
    position: relative;
  }
  .rbc-time-view .rbc-allday-events {
    position: relative;
    z-index: 4;
  }
  .rbc-time-view .rbc-row {
    box-sizing: border-box;
    min-height: 20px;
  }
  .rbc-time-header {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .rbc-time-header.rbc-overflowing {
    border-right: 1px solid #ddd;
  }
  .rbc-rtl .rbc-time-header.rbc-overflowing {
    border-right-width: 0;
    border-left: 1px solid #ddd;
  }
  .rbc-time-header > .rbc-row:first-child {
    border-bottom: 1px solid #ddd;
  }
  .rbc-time-header > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #ddd;
  }
  .rbc-time-header-content {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-width: 0;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-left: 1px solid #ddd;
  }
  .rbc-rtl .rbc-time-header-content {
    border-left-width: 0;
    border-right: 1px solid #ddd;
  }
  .rbc-time-content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1 0 0%;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 100%;
    border-top: 2px solid #ddd;
    overflow-y: auto;
    position: relative;
  }
  .rbc-time-content > .rbc-time-gutter {
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
  }
  .rbc-time-content > * + * > * {
    border-left: 1px solid #ddd;
  }
  .rbc-rtl .rbc-time-content > * + * > * {
    border-left-width: 0;
    border-right: 1px solid #ddd;
  }
  .rbc-time-content > .rbc-day-slot {
    width: 100%;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
  }
  .rbc-current-time-indicator {
    position: absolute;
    z-index: 3;
    height: 1px;
    background-color: #74ad31;
    pointer-events: none;
  }
  .rbc-event {
    width: 100%;
    position: relative; /* for resize handle and other inner positioning */
    display: block; /* make the <a> tag block */
    font-size: 0.85em;
    border-radius: 2px;
    padding: 0;
    background-color: #abaeb3;
    font-weight: normal; /* undo jqui's ui-widget-header bold */
    border: none;
    padding-bottom: 1px;
    line-height: 1.2;
    text-align: left;
  }
  .rbc-event.event-1F3FB9 {
    background-color: #1f3fb9;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(0, 188, 212, 0.4);
  }
  .rbc-event.event-880E4F {
    background-color: #880e4f;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(76, 175, 80, 0.4);
  }
  .rbc-event.event-BF00B0 {
    background-color: #bf00b0;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(255, 152, 0, 0.4);
  }
  .rbc-event.event-0A85FF {
    background-color: #0a85ff;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(244, 67, 54, 0.4);
  }
  .rbc-event.event-D81B60 {
    background-color: #d81b60;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(233, 30, 99, 0.4);
  }
  .rbc-event.event-79009B {
    background-color: #79009b;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(233, 30, 99, 0.4);
  }
  .rbc-event.event-5AB4FF {
    background-color: #5ab4ff;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(233, 30, 99, 0.4);
  }
  .rbc-event.event-F06292 {
    background-color: #f06292;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(233, 30, 99, 0.4);
  }
  .rbc-event.event-E157CA {
    background-color: #e157ca;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(233, 30, 99, 0.4);
  }
  
  .rbc-event.event-default.rbc-selected {
    background-color: #abaeb3;
  }
  
  .rbc-event.event-default {
    background-color: #abaeb3;
  }
  
  .rbc-event .rbc-event-content {
    position: relative;
    z-index: 2;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
    padding: 0 5px;
  }
  .cancellation-container {
    padding-top: 25px;
  }
  .cancellation-title {
    margin-bottom: 0 !important;
  }
  
  .CustomInput-labelRoot-496 {
    top: 0px !important;
  }
  
  .fullWidth-input .MuiGrid-root .MuiGrid-root {
    width: 100% !important;
    padding: 0 !important;
    max-width: 100%;
    flex-basis: 100%;
  }
  
  .align-right {
    text-align: end !important;
  }
  
  .center-align {
    text-align: center !important;
  }
  
  .chechbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #input-patient {
    display: none;
  }
  
  .radio-contaiter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50rem;
    height: 4.375rem;
  }
  
  .label-chechbox-container {
    margin: 0.625rem 1.25rem 0 0;
  }
  
  .frecuency-input {
    width: 500px;
  }
  .frecuency-input input {
    margin: 0;
  }
  
  .label-radiobox-container {
    margin: 0rem 1.25rem 0 0;
    width: 18.75rem;
  }
  
  .buttons-container {
    display: flex;
    justify-content: space-between;
    margin: 1.25rem;
    width: 100%;
  }
  
  .button-submit-container {
    display: flex;
    justify-content: flex-end;
  }
  
  .container-dressing {
    display: flex;
  }
  
  .header-internment-table {
    display: flex;
    justify-content: space-between;
  }
  
  .header-buttons {
    display: flex;
    margin: 1.25rem;
  }
  
  .header-icon {
    height: 2.1875rem;
  }
  
  .button-new-int {
    height: 2.8125rem;
    margin-right: 0.625rem;
  }
  
  .modal-medication {
    width: 70%;
    margin: 0 auto;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
  }
  
  .delete-medication-button {
    margin-top: 1.25rem;
  }
  
  .button-add-option {
    display: flex;
  }
  
  .title-pathologies-section {
    margin-left: 0.8125rem;
  }
  
  .startDate {
    padding-right: 10px;
  }
  
  .endDate {
    padding: 0 15px;
  }
  
  .add-pathology-button {
    margin: 15px;
  }
  
  .hospitalization-detail {
    margin: 12px;
  }
  
  .hospitalization-detail-date {
    font-size: 12px;
    margin-top: -5px;
    color: darkgrey;
  }
  
  .hospitalization-detail-message {
    font-size: 13px;
    margin-left: 15px;
    margin-top: -5px;
    color: rgb(201, 200, 200);
  }
  
  .hospitalization-detail-text {
    font-size: 15px;
    margin-top: -5px;
  }
  
  .no-pathology-message {
    font-size: 13px;
    margin-left: 30px;
    margin-top: -5px;
    color: rgb(201, 200, 200);
  }
  
  .error-text {
    font-size: 13px;
    margin-left: 30px;
    margin-top: -5px;
    color: #f44336;
  }
  
  .separator {
    height: 0.1px;
    background-color: rgb(201, 200, 200);
    width: 100%;
    border-style: none;
    margin: 10px 30px;
  }
  
  .button-add-services {
    display: flex !important;
    justify-content: flex-end !important;
  }
  
  .buttons-containers {
    margin-left: -18px !important;
  }
  