.profile-menu .MuiMenuItem-gutters.MuiListItem-button:hover {
    background-color: rgba(0, 0, 0, 0.06) !important;
    color: #333 !important;
    box-shadow: none !important;
}
.selected-company-value {
    padding: 10px;
    cursor: pointer
}
.notifications-menu div[role="tooltip"] {
    top: 0 !important;
}
.notifications-container {
    min-width: 30vw !important;
    max-height: 80vh;
    padding: 16px 10px;
    overflow: auto;
}
.notifications-header {
    padding: 0 15px 8px;
}

button.notification-link {
    text-decoration: underline;
    color:rgb(29, 6, 202);
    padding: 0;
}

.notifications-header h4 {
    border-bottom: 1px solid #ddd;
    font-weight: 500;
    margin-top: 0;
    padding-bottom: 8px;
    text-transform: none;
}
.notification-container {
    padding-bottom: 16px;
    padding-top: 16px;
    transition: all 150ms linear;
    text-transform: none;
}
.notification-container:hover {
    background-color: #f4f5f7;
}
.notification-container .message {
    color: rgba(0, 0, 0, 0.87);
    line-height: 1.4;
    white-space: normal;
}
.notification-container .datetime {
    color: #5DC4B9;
    font-weight: bold;
    margin-top: 20px;
}
.notification-container.read .message,
.notification-container.read button.notification-link,
.notification-container.read .datetime {
    color: #676666ad;
}
.marked-row {
    display: flex;
    align-items: center;
    justify-content: center;
 }
 .marked-row .marked {
    background: #5DC4B9;
    border-radius: 50% !important;
    height: 12px;
    width: 12px;
    padding: 0 !important;
    display: inline-block;
    cursor: pointer;
}
.marked-row .marked:hover {
    background-color: #5DC4B9;
    box-shadow: 0 7px 13px -12px rgb(0 176 2400 / 42%),
    0 4px 12px 0px rgb(0 0 0 / 12%),
    0 8px 10px -5px rgb(0 176 2400 / 20%);
}
.notification-container.read .marked,
.notification-container.read .marked:hover {
    background: #676666ad;
}
.notifications-footer {
    color: rgb(9, 35, 121);
    padding: 30px 0 0;
    text-align: center;
}
.notifications-footer img {
    display: inline-flex;
    max-width: 50px;
}
.notifications-footer p {
    padding: 0 30px;
    font-size: 0.8rem;
    font-weight: 300;
    display: block;
    text-transform: none;
}

:visited {
    color: rgb(24, 112, 127) !important;
}

.menu-item {
    text-decoration: none;
    font-size: 1em;
    font-family: "Roboto", 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    padding-top: 6px;
    letter-spacing: 0.00938em;
    padding-bottom: 6px;
    color: #495057;
}

#profile-menu-list {
    padding: 0;
}
#profile-menu-list .MuiList-padding {
    padding: 0;
}
#profile-menu-list li {
    cursor: default;
    margin: 0;
}
li.light-grey {
    background-color: rgba(0, 0, 0, 0.02);
}
li.text-right {
    text-align: right;
}
.profile-menu div[role="tooltip"] {
    left: -227px;
    min-width: 280px;
}
.current-user {
    margin: 10px 0;
    white-space: nowrap;
}
.current-user .user-avatar {
    display: inline-block;
    height: 80px;
    padding-right: 20px;
    vertical-align: middle;
    width: 80px;
}
.current-user .user-avatar .avatar {
    border-radius: 50%;
    height: 80px;
    overflow: hidden;
    position: relative;
    width: 80px;
}
.current-user .user-avatar .avatar img {
    display: block;
    max-width: 100%;
}
.current-user .user-avatar .avatar .button-action {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
}
.current-user .user-avatar .avatar .button-action>button {
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    font-size: .6rem;
    font-weight: 500;
    margin: 0;
    padding: 7px 0;
    width: 100%;
}
.current-user .user-info {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 100px);
}
.data-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.data-name span,
.data-name strong,
.data-name small {
    display: block;
}
.data-name strong {
    font-size: 110%;
}
.data-name small {
    opacity: 0.7;
}
.MuiInputBase-input {
    font-size: 1.8rem;
}

.fileinput input[type="file"] {
    display: none;
}

.companies-list {
    max-height: 8em;
    overflow-y: auto;
}

.divider-title {
    color: #999;
    font-size: 11px;
    font-weight: 700;
    line-height: 1.45455;
    margin: 14px 0 6px;
    padding: 0 20px;
    text-transform: uppercase;
}

#profile-menu-list li.item-menu-link {
    cursor: pointer;
}

