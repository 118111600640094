.supplycatalog {
    display: flex;
    justify-content: space-between;
}
.supplycatalog-content {
    width: calc(100% - 260px);
}
.filtrable-table .ReactTable .rt-thead.-header {
    z-index: 3;
}
.supplycatalog-filters {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.supplycatalog-filters-row {
    width: 100%;
}
.supplycatalog-dropdown {
    width: 20%;
}
.supplycatalog-content-mini {
    width: calc(100% - 80px)
}
.supplycatalog-content-header, .supplycatalog-content-header-mini {
    min-height: 70px;
}
.supplycatalog-content-header header{
    width: calc(100% - 260px);
}
.supplycatalog-content-header-mini header {
    width: calc(100% - 80px)
}
.supplycatalog-content-body {
    max-height: 500px;
    overflow: auto;
    display: flex;
    justify-content: space-evenly;
}
.supplycatalog-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
}
.supplycatalog-modules {
    width: 50%;
    padding: 0 1rem;
}
.supplycatalog-label.MuiFormLabel-root {
    padding: 0 1rem 0 0;
}
.supplycatalog-input {
    display: flex;
    align-items: baseline;
    justify-content: space-evenly;
    height: 70px;
}
.supplycatalog-content-body div {
    width: 100%;
}
.MuiFormControl-root.supplycatalog-dropdown {
    min-width: 150px;
    max-width: 150px;
}
.supplycatalog-modules-button {
    float: right;
}
.supplycatalog-input-number {
    max-width: 50px;
}
.supplycatalog-dialog {
    width: 60%;
    margin: auto;
}
.supplycatalog-dialog .supplycatalog-input {
    justify-content: space-between;
}
.supplycatalog-actions {
    display: flex;
    justify-content: center;
}
.supplycatalog-actions button {
    padding: 5px;
    width: auto;
    height: auto;
    min-width: auto;
    font-size: 12px;
}
supplycatalog-button,
.supplycatalog-submit {
    justify-content: flex-end !important;
    padding: 10px 5px 0 !important;
}
.supplycatalog-button-icon.MuiSvgIcon-root {
    margin: auto;
}

@media screen and (max-width: 976px) {
    .supplycatalog-content {
        width: 100%;
    }
    .supplycatalog-content-header header{
        width: 100%;
    }
    .supplycatalog-content-body {
        flex-direction: column;
    }
    .supplycatalog-modules, .supplycatalog-form {
        width: 100%;
    }
}