.routes {
    display: flex;
    justify-content: space-between;
}
.routes-content {
    width: calc(100% - 260px);
}
.routes-marker img{
    width: 32px;
    height: 32px;
}
.routes-filters {
    display: flex;
    margin-bottom: 17px;
}
.routes-filters-row {
    width: 100%;
}
.routes-dropdown {
    width: 100%;
}
.item-dateInput .MuiFormControl-root {
    margin-bottom: 0;
    padding-top: 0;
}
.routes-content-mini {
    width: calc(100% - 80px)
}
.routes-content-header, .routes-content-header-mini {
    min-height: 70px;
}
.routes-content-header header{
    width: calc(100% - 260px);
}
.routes-content-header-mini header {
    width: calc(100% - 80px)
}
.routes-content-body {
    max-height: 500px;
    overflow: auto;
}
.routes-button {
    float: right;
}
.routes-button-icon.MuiSvgIcon-root {
    margin: auto;
}
.routes .btn.btn-primary {
    color: #FFF;
    border: none;
    margin: .3125rem 1px;
    cursor: pointer;
    padding: 12px 30px;
    position: relative;
    min-width: auto;
    font-size: 12px;
    min-height: auto;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    text-align: center;
    font-weight: 400;
    will-change: box-shadow, transform;
    line-height: 1.42857143;
    white-space: nowrap;
    touch-action: manipulation;
    border-radius: 3px;
    text-transform: uppercase;
    letter-spacing: 0;
    vertical-align: middle;
    box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
    background-color: #5DC4B9;
}
.regular-map {
    border-radius: 6px;
    height: 500px;
    overflow: 'hidden';
    width: 100%;
}

@media screen and (max-width: 976px) {
    .routes-content {
        width: 100%;
    }
    .routes-content-header header{
        width: 100%;
    }
}

.label-link {
    top: 10px;
    color: rgba(0, 0, 0, 0.54) !important;
    font-size: 0.665em !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
}


.value-link {
    top: 10px;
    color: rgba(57, 33, 163, 0.822) !important;
    font-size: 13px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    padding-bottom: 10px;
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 30px;
    border-bottom: #cacaca dotted 1px;
}