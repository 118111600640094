.schedules {
    display: flex;
    justify-content: space-between;
}
.schedules-content {
    width: calc(100% - 260px);
}
.schedules-filters {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.schedules-filters-row {
    width: 100%;
}
.schedules-dropdown {
    width: 20%;
}
.schedules-content-mini {
    width: calc(100% - 80px)
}
.schedules-content-header, .schedules-content-header-mini {
    min-height: 70px;
}
.schedules-content-header header{
    width: calc(100% - 260px);
}
.schedules-content-header-mini header {
    width: calc(100% - 80px)
}
.schedules-content-body {
    max-height: 500px;
    overflow: auto;
    display: flex;
    justify-content: space-evenly;
}
.schedules-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
}
.schedules-modules {
    width: 50%;
    padding: 0 1rem;
}
.schedules-label.MuiFormLabel-root {
    padding: 0 1rem 0 0;
}
.schedules-input {
    display: flex;
    align-items: baseline;
    justify-content: space-evenly;
    height: 70px;
}
.schedules-content-body div {
    width: 100%;
}
.MuiFormControl-root.schedules-dropdown {
    min-width: 150px;
    max-width: 150px;
}
.scheduless-submit {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.schedules-modules-button {
    float: right;
}
.schedules-input-number {
    max-width: 50px;
}
.schedules-dialog {
    width: 60%;
    margin: auto;
}
.schedules-dialog .schedules-input {
    justify-content: space-between;
}
.chart-container {
    padding: 10px 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.chart-header {
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    color: rgba(0,0,0,.54);
    text-transform: uppercase;
}
.vitalSignsView .rowItem {
    padding: 8px 30px !important;
}
.vitalSignsView .rowItem:not(.chartistGraph) + .rowItem {
    margin-top: 15px;
}
.vitalSignsView .chartistGraph.first {
    margin-top: 45px !important;
}
.vitalSignsView .label-form,
.chart-header-low {
    font-size: 13px !important;
}
.chart-header-low {
    color: rgba(0,0,0,.54);
    font-weight: 500 !important;
    margin-bottom: 8px;
    text-align: center;
}
.chartistGraphBody {
    padding: 0 24px;
}

@media screen and (max-width: 976px) {
    .schedules-content {
        width: 100%;
    }
    .schedules-content-header header{
        width: 100%;
    }
    .schedules-content-body {
        flex-direction: column;
    }
    .schedules-modules, .schedules-form {
        width: 100%;
    }
}
