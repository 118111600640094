.chechbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#input-patient {
  display: none;
}

.radio-contaiter-diabetic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50rem;
  height: 3rem;
  margin-left: 15px;
}

.radio-contaiter-diabetic-vaccination {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50rem;
  height: 4.5rem;
  margin-left: 0.9375rem;
}

.label-chechbox-container {
  margin: 0.625rem 1.25rem 0 0;
}

.frecuency-input {
  width: 31.25rem;
}
.frecuency-input input {
  margin: 0;
}

.label-radiobox-diabetic {
  margin: 0rem 1.25rem 0 0;
  width: 41rem;
}

.label-radiobox-strong {
  margin: 0rem 1.25rem 0 0;
  width: 30rem;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  margin: 1.25rem;
  width: 100%;
}

.button-submit-container {
  display: flex;
  justify-content: flex-end;
}

.container-dressing {
  display: flex;
}

.header-internment-table {
  display: flex;
  justify-content: space-between;
}

.header-buttons {
  display: flex;
  margin: 1.25rem;
}

.header-icon {
  height: 2.1875rem;
}

.button-new-int {
  height: 2.8125rem;
  margin-right: 0.625rem;
}

.modal-medication {
  width: 70%;
  margin: 0 auto;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.delete-medication-button {
  margin-top: 1.25rem;
}

.button-add-option {
  display: flex;
}

.title-pathologies-section {
  margin-left: 0.8125rem;
}

.startDate {
  padding-right: 0.625rem;
}

.endDate {
  padding: 0 0.9375rem;
}

.add-pathology-button {
  margin: 0.9375rem;
}

.hospitalization-detail {
  margin: 0.75rem;
}

.hospitalization-detail-date {
  font-size: 0.75rem;
  margin-top: -0.3125rem;
  color: darkgrey;
}

.hospitalization-detail-message {
  font-size: 0.8125rem;
  margin-left: 0.9375rem;
  margin-top: -0.3125rem;
  color: rgb(201, 200, 200);
}

.hospitalization-detail-text {
  font-size: 0.9375rem;
  margin-top: -0.3125rem;
}

.no-pathology-message {
  font-size: 0.8125rem;
  margin-left: 1.875rem;
  margin-top: -0.3125rem;
  color: rgb(201, 200, 200);
}

.error-text {
  font-size: 0.8125rem;
  margin-left: 1.875rem;
  margin-top: -0.3125rem;
  color: #f44336;
}

.depression-section {
  color: rgb(150, 150, 150);
}

.contaiter-muscular-strength {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
}

.label-radiobox-container-bloodPressure {
  margin: 0.3125rem 0 -0.625rem 0.9375rem;
}

.division-bloodPressure {
  margin: 40px -25px 0 -20px;
}
