.MuiFormLabel-root.label-horizontal {
    color: rgba(0, 0, 0, 0.26);
    cursor: pointer;
    display: inline-flex;
    font-size: .875em;
    font-weight: 400;
    line-height: 1.428571429;
    padding-top: 39px;
    margin-right: 0;
}

.MuiFormLabel-root.label-horizontal--no-padding {
    cursor: pointer;
    display: inline-flex;
    font-size: .875em;
    font-weight: 400;
    line-height: 1.428571429;
    margin-right: 0;
}