* {
    font-family: 'Roboto', sans-serif;
  }
  
  .label-services {
      font-weight: 300;
      color: #3C4858;
      font-size: 1.185em;

  }
  .timeInput {
      margin-top: 5px !important;
  }
  .schedulePlan {
      margin-left: -30px;
  }
  .MuiDialog-paperWidthMd {
    max-width: 1024px !important;
  }

  .button-translado  {
      margin-top: 20px;
  }

  .margin-left {
      margin-left: 15px !important
  }

  .padding-top {
      margin-top: -10px;
      width: '100%';
  }

  .no-padding {
      width: 100% !important;
  }

  .right {
    align-content: right;
}