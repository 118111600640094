
.alerts {
    display: flex;
    justify-content: space-between;
}
.card-title {
    color: #3C4858;
    font-size: 1.3em;
    line-height: 1.4em;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 300;
    margin-bottom: 0;
    margin-top: 15px;
    min-height: auto;
    text-decoration: none;
}
.alerts-content {
    width: calc(100% - 260px);
}
.alerts-filters {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.alerts-filters-row {
    width: 100%;
}
.alerts-dropdown {
    width: 20%;
}
.alerts-content-mini {
    width: calc(100% - 80px)
}
.alerts-content-header, .alerts-content-header-mini {
    min-height: 70px;
}
.alerts-content-header header{
    width: calc(100% - 260px);
}
.alerts-content-header-mini header {
    width: calc(100% - 80px)
}
.alerts-content-body {
    max-height: 500px;
    overflow: auto;
    display: flex;
    justify-content: space-evenly;
}
.alerts-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
}
.alerts-modules {
    width: 50%;
    padding: 0 1rem;
}
.alerts-label.MuiFormLabel-root {
    padding: 0 1rem 0 0;
}
.alerts-input {
    display: flex;
    align-items: baseline;
    justify-content: space-evenly;
    height: 70px;
}
.alerts-content-body div:not(.-pageJump) {
    width: 100%;
}
.MuiFormControl-root.alerts-dropdown {
    min-width: 150px;
    max-width: 150px;
}
.alertss-submit {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.alerts-modules-button {
    float: right;
}
.alerts-input-number {
    max-width: 50px;
}
.alerts-dialog {
    width: 60%;
    margin: auto;
}
.alerts-dialog .alerts-input {
    justify-content: space-between;
}

@media screen and (max-width: 976px) {
    .alerts-content {
        width: 100%;
    }
    .alerts-content-header header{
        width: 100%;
    }
    .alerts-content-body {
        flex-direction: column;
    }
    .alerts-modules, .alerts-form {
        width: 100%;
    }
}
.notifications-menu div[role="tooltip"] {
    top: 100% !important;
}