.div {
  padding-right: 5px;
  padding-left: 5px;
  text-align: center;
}

.report-style-class {
  flex: 1;
  height: 100vh;
  width: '99%';
}
