.authorizer-form {
  z-index: 0;
}
.authorizer-form .authorizer {
  justify-content: space-between;
}

.authorizer-medical-name {
  width: 60%;
}

.authorizer-medical-registration {
  width: 30%;
}

.authorizer-contact-family-name {
  width: 60%;
}
.authorizer-contact-family-phone {
  width: 30%;
}
.authorizer-radio .MuiFormGroup-root {
  flex-direction: row;
}
.radio-input-group span,
.suffix-label {
  font-size: 1em;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
}
