.MuiGrid-item.schedule-form-submit {
    align-content: right;
}
.assign-professionals-form .MuiAccordion-rounded {
    border-radius: 0;
}

.assign-professionals-form:first-child .MuiAccordion-rounded {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.assign-professionals-form:last-child .MuiAccordion-rounded {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.assign-professionals-form > .MuiGrid-root {
    width: 100%;
}
.assign-professionals-form .MuiAccordion-root .MuiAccordionSummary-root {
    transition: all;
    background-color: rgba(0, 0, 0, 0.03);
}
.assign-professionals-form .speciality > div {
    padding: 0 !important;
}
.geographics .MuiTypography-body1 {
    font-size: 13px !important;
    opacity: .8;
}
div.speciality .checkLabel.geographics {
    margin-left: -16px;
}

.assign-professionals-form .select-input .MuiFormControl-fullWidth {
    margin-bottom: 10px;
}
.item-assign .select-input .MuiFormControl-fullWidth {
    margin-top: 0;
}
