.MuiFormLabel-root.radio-input-title {
    color: rgba(0, 0, 0, 0.87);
    padding: 0rem 0;
}
.radio-input-group {
    display: flex;
    align-items: center;
}

.radio-input .rdt .rdtPicker {
    margin-top: -325px;
}

.radio-input .rdt .rdtPicker:after {
    border-top: 0.4em solid #fff;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    bottom: -5px;
    left: 10px;
}

.radio-input .rdt .rdtPicker:before {
    display: none;
}
