.filters {
  align-items: center;
  clear: both;
  display: flex;
  justify-content: flex-start;
}
.filters.dashboard {
  align-items: flex-start;
}
.filters.dashboard .filters-actions {
  padding-top: 16px;
}
.filters .date-filter {
  z-index: 2;
}
.profesionals-dashboard .filters .professionals-filter {
  padding: 0 !important;
}
.filters .MuiSelect-select {
  white-space: normal;
}
.filters .MuiSelect-select div .MuiChip-root {
  margin: 2px;
}
.filters .MuiSelect-select div .MuiChip-root .MuiChip-label {
  font-size: 0.7rem;
}
.filters .MuiGrid-root .MuiGrid-grid-sm-12 {
  padding: 0 !important;
}
.MuiButton-root.RegularButton-block-290 + .MuiButton-root.RegularButton-block-290 {
  margin-left: 0;
}
.dashboard-filters .rdt {
  padding: 0 1rem 0 0;
}
.dashboard-filters-dropdown {
  width: 100%;
}

.dashboard-content {
  width: calc(100% - 260px);
}
.dashboard-content-mini {
  width: calc(100% - 80px);
}
.dashboard-content-header,
.dashboard-content-header-mini {
  min-height: 70px;
}
.dashboard-content-header header {
  width: calc(100% - 260px);
}
.dashboard-content-header-mini header {
  width: calc(100% - 80px);
}
.dashboard-content-body {
  max-height: 594px;
  overflow: auto;
}
.dashboard-charts {
  display: flex;
}
.dashboard-link {
  cursor: pointer;
  color: #00bcd4;
}
.dashboard .chips {
  display: flex;
  flex-wrap: wrap;
}

.dashboard-actions {
  display: flex;
  justify-content: center;
}
.dashboard-actions button {
  padding: 5px;
  width: auto;
  height: auto;
  min-width: auto;
  font-size: 12px;
}

.filters-actions .MuiButton-root + .MuiButton-root{
  margin-left: 0;
}
.ct-chart-white-colors .ct-horizontal{
  top: -12px
}
@media screen and (max-width: 976px) {
  .dashboard-content {
    width: 100%;
  }
  .dashboard-content-header header {
    width: 100%;
  }
}
.ReactTable .rt-td {
  margin: 10px 0;
}
.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.02);
}

.rt-tbody {
  font-size: 14px;
}


.ct-label.ct-label.ct-horizontal.ct-end {
  position: relative;
  text-align: center;
  transform-origin: 100% 0;
  left: 0px;
  transform: translateX(0%) translateY(10%) rotate(0deg) !important;
  white-space:nowrap;
}

.ct-chart-bar > .ct-labels > foreignObject >.ct-label.ct-horizontal.ct-end {
  left: 0 !important;
}
.MuiGrid-item.address-customer {
  margin-bottom: 8px;
}
.address-legend p {
  color: #495057;
}
.address-legend p img,
.address-legend p span {
  display: inline-block;
  vertical-align: top;
}
.label-legend-map {
  font-size: .875em;
  padding: 2px 0 0 8px;
  width: calc(100% - 30px);
}
.label-legend-map strong {
  font-weight: 500;
}

.mdc-chip__text {
  font-size: 14px !important;
  white-space: normal !important;
  box-sizing: unset;
}

.mdc-chip {
  height: unset !important;
}

.MuiIconButton-root.Mui-disabled {
  opacity: 0.7;
}
