.content, .content-mini {
    float: right;
}
.content {
    width: calc(100% - 260px);
}
.content-mini {
    width: calc(100% - 80px)
}

@media screen and (max-width: 967px) {
    .content {
        width: 100%
    }
}