.buttonFooter {
    align-self: flex-end;
    text-align: center;
}
.buttonFooterModal{
    text-align: right;
}
.appointment-modal-tools .buttonFooterModal {
    padding: 5px 0 0 !important;
}
.buttonPlusFooter {
    display: flex;
    align-self: flex-end !important;
}
.actions-table {
    padding: 0 20px 0.9375rem;
}
.justify-center {
    justify-content: center;
}
.buttons-bottom-modal{
    margin-top: 15px !important;
} 