.inputPhone {
	--PhoneInputCountryFlag-borderWidth: 0px;
    --PhoneInputCountrySelectArrow-color: gray;
}


.PhoneInputInput {
    border: none;
}

.phone {
    margin-left: 15px !important;
    border-bottom: 0.5px solid #D2D2D2;
    margin-right: 15px !important;
    padding-top: 10px;
    padding-bottom: 10px;
}

.MuiInput-underline:before {
    border-color: #D2D2D2 !important;
}
.MuiInput-underline:after {
    border-bottom-width: 1px !important;
}


.PhoneInputInput:focus {
    border: none;
    outline: none;
    
}
